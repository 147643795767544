<template>
  <div class="pbf">
     
     <BrandTabReviews
      :inurl="'/users/' + props.id + '/reviews'"   
      :grower-id="props.id"  
      :sort-options="[
        {id:'rate', name: $t('universal_sort_review')},    
        {id:'create', name: $t('universal_sort_creation_date')},
        {id:'az', name: $t('universal_sort_az')},
        {id:'gwatt', name: $t('universal_sort_gwatt')},
        {id:'gplant', name: $t('universal_sort_gplant')},
      ]" 
      />

  </div>
</template>

<script setup>

const route = useRoute();
const { $api, $ga, $helper, $tagsUtil, $head } = useNuxtApp();
var grower_id = ref(route.params.id);

const props = defineProps({
  data: {
    type: Object,
    required: true
  },
  id: {
    type: Number
  }
});

useHead(useNuxtApp().$head.getGrowerReviews(props.data));

// head() {
//     return this.$head.getGrowerReviews(this.data)
//   },
</script>

<style scoped>
 

</style>
